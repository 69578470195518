import React, { ChangeEvent, useContext, useRef } from 'react';
import { Button, Card, Grid, SelectChangeEvent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { PolicyContext } from '../../../..';
import PropertyAndSelectInput from '../../../PropertyAndSelectInput';
import { AppDispatch } from '../../../../../../redux/store';
import { updateMessage } from '../../../../../../redux/slices/androidPolicy/androidPolicySlice';
import { messageInit } from '../../../../../../utils/common-constants';
import DeviceProfileType from '../../../../constants';
import PropertyAndInput from '../../../PropertyAndInput';
import { ANDROID_POLICY_MSG } from '../../../../../../messages';
import isValidDomain from '../../../../../../utils/validator';

const initWifiConfig = {
  guid: '',
  name: '',
  type: 'WiFi',
  wifi: {
    ssid: '',
    security: 'none',
    autoconnect: false,
    passphrase: '',
    macAddressRandomizationMode: '',
    eap: {
      eapInner: '',
      eapOuter: '',
      eapIdentity: '',
      eapDomainSuffixMatch: [],
      eapServerCARef: '',
      eapClientCertKeyPairAlias: '',
      eapClientCertType: '',
      eapClientCertRef: '',
    },
  },
};

function WifiConfiguration() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const dispatch = useDispatch<AppDispatch>();
  const { networkConfigurations, wifiConfigDisabled } = policy.network.openNetworkConfiguration;
  const debounceTimer = useRef<number>(0);
  const handleNameTypeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number,
  ) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.map(
            (config, id) =>
              id === index
                ? {
                    ...config,
                    [name]: value,
                  }
                : config,
          ),
        },
      },
    }));
  };

  const handleWifiChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number,
  ) => {
    const { name, value } = event.target;

    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.map(
            (config, id) =>
              id === index
                ? {
                    ...config,
                    wifi: {
                      ...config.wifi,
                      [name]: value,
                    },
                  }
                : config,
          ),
        },
      },
    }));
  };

  const handleEapChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number,
  ) => {
    const { name, value } = event.target;
    const updatedValue: string | string[] =
      name === 'eapDomainSuffixMatch' ? value.split(',').map((item) => item.trim()) : value;

    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.map(
            (config, id) =>
              id === index
                ? {
                    ...config,
                    wifi: {
                      ...config.wifi,
                      eap: {
                        ...config.wifi.eap,
                        [name]: updatedValue,
                      },
                    },
                  }
                : config,
          ),
        },
      },
    }));
  };

  const handleEapBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === 'eapDomainSuffixMatch') {
      clearTimeout(debounceTimer.current);
      // there is a time differnce when the focus of the field is changing from one tab to other via mouse pointer
      // so we need to delay the time between input field switch when using mouse pointer
      debounceTimer.current = window.setTimeout(() => {
        const domainSuffixMatch = value.split(',').map((item) => item.trim());
        // Domain name validation check
        const areValidDomain = domainSuffixMatch.every((domain) => isValidDomain(domain));

        dispatch(
          updateMessage({
            ...messageInit,
            error: !areValidDomain,
            errorMessage: areValidDomain ? '' : 'Enter comma seperated valid domain names',
          }),
        );
      }, 500);
    }
  };

  const handleAddNewNetworkConfiguration = () => {
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: [
            ...prev.network.openNetworkConfiguration.networkConfigurations,
            initWifiConfig,
          ],
        },
      },
    }));
  };

  const removeNetworkConfiguration = (index: number) => {
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          networkConfigurations: prev.network.openNetworkConfiguration.networkConfigurations.filter(
            (_, id) => id !== index,
          ),
        },
      },
    }));
  };

  const handleWifiConfig = () => {
    setPolicy((prev) => ({
      ...prev,
      network: {
        ...prev.network,
        openNetworkConfiguration: {
          ...prev.network.openNetworkConfiguration,
          wifiConfigDisabled: !prev.network.openNetworkConfiguration.wifiConfigDisabled,
        },
      },
    }));
  };

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item xs={6}>
        <PropertyAndSelectInput
          data={{
            property: 'Configure other WiFi networks',
            name: 'wifiConfigDisabled',
            menuItems: [
              { view: 'Allow', value: true },
              { view: 'Deny', value: false },
            ],
            value: wifiConfigDisabled,
            androidVersion: '6.0+',
            profileType: `${DeviceProfileType.COMPANY_OWNED}`,
            handleChange: () => handleWifiConfig(),
          }}
        />
      </Grid>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item sm={6}>
          {networkConfigurations.map((networkConfig, index) => (
            <Card
              key={networkConfig.guid}
              sx={{ padding: 2, marginBottom: 2, display: 'flex', flexDirection: 'column' }}
            >
              <PropertyAndInput
                property='Network Name'
                name='name'
                infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_NAME}
                value={networkConfig.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleNameTypeChange(e, index)}
                androidVersion='6.0+'
                profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                isRequired
                placeholder='Network Name'
              />
              <PropertyAndInput
                property='SSID'
                name='ssid'
                infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.SSID}
                value={networkConfig.wifi?.ssid || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleWifiChange(e, index)}
                androidVersion='6.0+'
                profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                placeholder='WiFi Name'
                isRequired
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Security',
                  name: 'security',
                  infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.SECURITY,
                  menuItems: [
                    { view: 'None', value: 'none' },
                    {
                      view: 'WEP-PSK',
                      value: 'wep_psk',
                      tooltip:
                        ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_TOOLTIP
                          .ADD_NETWORK_CONFIG_SECURITY.WEP_PSK,
                    },
                    {
                      view: 'WPA-PSK',
                      value: 'wpa_psk',
                      tooltip:
                        ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_TOOLTIP
                          .ADD_NETWORK_CONFIG_SECURITY.WPA_PSK,
                    },
                    {
                      view: 'WPA-EAP',
                      value: 'wpa_eap',
                      tooltip:
                        ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_TOOLTIP
                          .ADD_NETWORK_CONFIG_SECURITY.WPA_EAP,
                    },
                    {
                      view: 'WEP-8021X',
                      value: 'wep_8021x',
                      tooltip:
                        ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_TOOLTIP
                          .ADD_NETWORK_CONFIG_SECURITY.WEP_8021X,
                    },
                    {
                      view: 'WPA3-Enterprise_192',
                      value: 'wpa3_enterprise192',
                      tooltip:
                        ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_TOOLTIP
                          .ADD_NETWORK_CONFIG_SECURITY.WPA3_ENTERPRISE_192,
                    },
                  ],
                  value: networkConfig.wifi?.security,
                  handleChange: (e: SelectChangeEvent<string>) => handleWifiChange(e, index),
                  androidVersion: '6.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                  isRequired: true,
                }}
              />
              {(networkConfig.wifi?.security === 'wpa_psk' ||
                networkConfig.wifi?.security === 'wep_psk') && (
                <PropertyAndInput
                  property='Passphrase'
                  name='passphrase'
                  infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.PASSPHRASE}
                  value={networkConfig.wifi?.passphrase || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleWifiChange(e, index)}
                  androidVersion='6.0+'
                  profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                  placeholder='Password'
                />
              )}
              {['wpa_eap', 'wep_8021x', 'wpa3_enterprise192'].includes(
                networkConfig.wifi?.security,
              ) && (
                <>
                  <PropertyAndSelectInput
                    data={{
                      property: 'EAP Inner',
                      name: 'eapInner',
                      menuItems: [
                        { view: 'MSCHAPv2', value: 'mschapv2' },
                        { view: 'PAP', value: 'pap' },
                      ],
                      infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_INNER,
                      value: networkConfig.wifi?.eap?.eapInner,
                      handleChange: (e: SelectChangeEvent<string>) => handleEapChange(e, index),
                      androidVersion: '6.0+',
                      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                      isRequired: true,
                    }}
                  />
                  <PropertyAndSelectInput
                    data={{
                      property: 'EAP Outer',
                      name: 'eapOuter',
                      menuItems: [
                        { view: 'EAP-AKA', value: 'eap_aka' },
                        { view: 'EAP-SIM', value: 'eap_sim' },
                        { view: 'EAP-TLS', value: 'eap_tls' },
                        { view: 'EAP-TTLS', value: 'eap_ttls' },
                        { view: 'PEAP', value: 'peap' },
                      ],
                      infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_OUTER,
                      value: networkConfig.wifi?.eap?.eapOuter,
                      handleChange: (e: SelectChangeEvent<string>) => handleEapChange(e, index),
                      androidVersion: '6.0+',
                      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                      isRequired: true,
                    }}
                  />
                  <PropertyAndInput
                    property='EAP Identity'
                    name='eapIdentity'
                    infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_IDENTITY}
                    value={networkConfig.wifi?.eap?.eapIdentity || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter EAP Identity'
                  />
                  <PropertyAndInput
                    property='EAP Domain Suffix Match'
                    name='eapDomainSuffixMatch'
                    infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_DOMAIN_SUFFIX}
                    value={(networkConfig.wifi?.eap?.eapDomainSuffixMatch || []).join(', ')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleEapBlur(e)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter comma-separated domain suffixes'
                  />
                  <PropertyAndInput
                    property='EAP Server CA Reference'
                    name='eapServerCARef'
                    infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_SERVER_CA_REF}
                    value={networkConfig.wifi?.eap?.eapServerCARef || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                    androidVersion='6.0+'
                    profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                    placeholder='Enter CA Reference'
                  />
                  <PropertyAndSelectInput
                    data={{
                      property: 'EAP Client Certificate Type',
                      name: 'eapClientCertType',
                      menuItems: [
                        { view: 'Ref', value: 'ref' },
                        { view: 'KeyPairAlias', value: 'keypair_alias' },
                      ],
                      infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_CLIENT_CERT_TYPE,
                      value: networkConfig.wifi?.eap?.eapClientCertType,
                      handleChange: (e: SelectChangeEvent<string>) => handleEapChange(e, index),
                      androidVersion: '6.0+',
                      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                      isRequired: true,
                    }}
                  />
                  {networkConfig?.wifi.eap?.eapClientCertType === 'ref' && (
                    <PropertyAndInput
                      property='EAP Client Certificate Reference'
                      name='eapClientCertRef'
                      infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_CLIENT_CERT_REF}
                      value={networkConfig.wifi?.eap?.eapClientCertRef || ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                      androidVersion='6.0+'
                      profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                      placeholder='Enter Certificate Reference'
                    />
                  )}
                  {networkConfig?.wifi.eap?.eapClientCertType === 'keypair_alias' && (
                    <PropertyAndInput
                      property='EAP Client Certificate KeyPairAlias'
                      name='eapClientCertKeyPairAlias'
                      infoText={ANDROID_POLICY_MSG.NETWORK_INFOTEXT.EAP_CLIENT_CERT_KEYPAIR_ALIAS}
                      value={networkConfig.wifi?.eap?.eapClientCertKeyPairAlias || ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleEapChange(e, index)}
                      androidVersion='6.0+'
                      profileType={`${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`}
                      placeholder='Enter Certificate KeyPairAlias'
                    />
                  )}
                </>
              )}
              <PropertyAndSelectInput
                data={{
                  property: 'Auto Connect',
                  name: 'autoconnect',
                  infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.AUTO_CONNECT,
                  menuItems: [
                    { view: 'Allow', value: true },
                    { view: 'Deny', value: false },
                  ],
                  value: networkConfig.wifi?.autoconnect,
                  handleChange: (e: SelectChangeEvent<string>) => handleWifiChange(e, index),
                  androidVersion: '6.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'MAC Address Randomization Mode',
                  name: 'macAddressRandomizationMode',
                  infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.RANDOM_MAC_ADDRESS_MODE,
                  menuItems: [
                    {
                      view: 'Automatic',
                      value: 'automatic',
                      tooltip:
                        ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_TOOLTIP
                          .ADD_NETWORK_CONFIG_SECURITY.RANDOM_MAC_ADDRESS_MODE_MENU.AUTOMATIC,
                    },
                    {
                      view: 'Hardware',
                      value: 'hardware',
                      tooltip:
                        ANDROID_POLICY_MSG.NETWORK_INFOTEXT.NETWORK_TOOLTIP
                          .ADD_NETWORK_CONFIG_SECURITY.RANDOM_MAC_ADDRESS_MODE_MENU.HARDWARE,
                    },
                  ],
                  value: networkConfig.wifi?.macAddressRandomizationMode,
                  handleChange: (e: SelectChangeEvent<string>) => handleWifiChange(e, index),
                  androidVersion: '13.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                }}
              />

              <div style={{ marginTop: 'auto', width: 'fit-content', marginLeft: 'auto' }}>
                <Button
                  onClick={() => removeNetworkConfiguration(index)}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))}
          <Button variant='contained' onClick={handleAddNewNetworkConfiguration}>
            Add Network Configuration
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WifiConfiguration;
