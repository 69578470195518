import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ANDROID_OS, APPLE_OS, DEVICE_ENROLLMENT_CONST, EMPTY_STRING } from '../../constants';
import { ANDROID_ENROLLMENT_MSG } from '../../messages';
import { updateMessage as updateMessageAndroid } from '../../redux/slices/androidDevice/androidDeviceSlice';
import { updateMessage as updateMessageApple } from '../../redux/slices/appleDevice/appleDeviceSlice';
import { getUsers, setAll, updateUsersArray } from '../../redux/slices/users/usersSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { IUser, messageInit, userInit } from '../../utils/common-constants';
import RadioOptions from '../RadioOptions';
import EnrollmentSteps from './EnrollmentSteps';
import {
  enrollmentButtonLabels,
  enrollmentDetailsInit,
  IAndroidInvitationPayload,
  IAppleInvitationPayload,
  IEnrollmentDetails,
  ISelectItem,
} from './definitions';
import { Tooltip } from '../../pages/AndroidPolicies/components/InfoTooltip';
import EnrollmentAutoComplete from './EnrollmentAutoCompleteInput';

interface IDeviceApprovalFlows {
  needMFA: boolean;
  needAdminApproval: boolean;
}

const IDeviceApprovalFlowsInit: IDeviceApprovalFlows = {
  needMFA: false,
  needAdminApproval: false,
};

interface IProps {
  deviceType: 'android' | 'apple';
  title: string;
  open: boolean;
  handleDialog: () => void;
  groupList: ISelectItem[];
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  sendEnrollmentInvitation: any;
}

interface IEnrollFormDeviceGroup {
  _id?: string;
  value1: string;
  value2: string;
}

const initialGroup: IEnrollFormDeviceGroup = {
  _id: '',
  value1: '',
  value2: '',
};

function EnrollmentDialog(props: IProps) {
  const { deviceType, title, open, handleDialog, groupList, sendEnrollmentInvitation } = props;

  const enterprise = useSelector((state: RootState) => state.androidEnterprise.enterprise);
  const { users, searchKey, loading } = useSelector((state: RootState) => state.user);
  const initialUser = useRef<IUser[]>([]); // using this to store users we got during component mount. using this we can avoid repeatative calls when search key is empty.

  const [user, setUser] = useState<IUser>(userInit);
  const [ownershipType, setOwnershipType] = useState<string>(
    DEVICE_ENROLLMENT_CONST.DEVICE_PERSONAL,
  );
  const [mfaGroup, setMFAGroup] = useState<IEnrollFormDeviceGroup>(initialGroup);
  const [showMFAAlert, setShowMFAAlert] = useState<boolean>(false);
  const [approvalNeeded, setApprovalNeeded] = useState<IDeviceApprovalFlows>(
    IDeviceApprovalFlowsInit,
  );
  const [enrollmentOption, setEnrollmentOption] = useState<string>(
    DEVICE_ENROLLMENT_CONST.CHOICE_SEND_EMAIL,
  );
  const [enrollmentDetails, setEnrollmentDetails] = useState<IEnrollmentDetails>(
    enrollmentDetailsInit,
  );
  const [defaultGroup, setDefaultGroup] = useState<IEnrollFormDeviceGroup>(initialGroup);

  const dispatch = useDispatch<AppDispatch>();

  const resetForm = () => {
    setUser(userInit);
    setOwnershipType(DEVICE_ENROLLMENT_CONST.DEVICE_PERSONAL);
    setEnrollmentOption(DEVICE_ENROLLMENT_CONST.CHOICE_SEND_EMAIL);
    setEnrollmentDetails(enrollmentDetailsInit);
    setMFAGroup(initialGroup);
    setShowMFAAlert(false);
    setDefaultGroup(initialGroup);
  };

  const handleDialogWithReset = () => {
    resetForm();
    handleDialog();
  };

  const selectUser = (value: ISelectItem) => {
    const userDetails: ISelectItem = value ?? initialGroup;
    setUser({
      _id: userDetails._id,
      email: userDetails.value1,
      userName: userDetails.value2,
    });
  };

  const selectGroup = (value: ISelectItem) => {
    const groupDetail: ISelectItem = value ?? initialGroup;
    setDefaultGroup({
      _id: groupDetail._id,
      value1: groupDetail.value1,
      value2: groupDetail.value2,
    });
  };

  const selectMFAGroup = (value: ISelectItem) => {
    const groupDetail: ISelectItem = value ?? initialGroup;
    setMFAGroup({
      _id: groupDetail._id,
      value1: groupDetail.value1,
      value2: groupDetail.value2,
    });
  };

  const handleCheckBoxes = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setApprovalNeeded((prev) => ({
      ...prev,
      [name]: checked,
    }));

    if (name === DEVICE_ENROLLMENT_CONST.MFA_ENFORCEMENT) {
      setShowMFAAlert(checked);
    }
  };

  const handleOwnershipOption = (e: ChangeEvent<HTMLInputElement>) => {
    setOwnershipType((e.target as HTMLInputElement).value);
    setEnrollmentDetails(enrollmentDetailsInit);
  };

  const handleEnrollmentButton = (e: ChangeEvent<HTMLInputElement>) => {
    setEnrollmentOption((e.target as HTMLInputElement).value);
  };

  const sendEnrollmentInvite = async (sendEmail: boolean) => {
    const approvalStatusOptions = {
      ...approvalNeeded,
      mfaGroup: mfaGroup.value1,
    };

    if (deviceType === ANDROID_OS) {
      if (!user._id || user._id === EMPTY_STRING) {
        dispatch(
          updateMessageAndroid({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a user',
          }),
        );
        return;
      }
      if (!defaultGroup._id || defaultGroup._id === EMPTY_STRING) {
        dispatch(
          updateMessageAndroid({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a group',
          }),
        );
        return;
      }

      const payload: IAndroidInvitationPayload = {
        enterpriseId: enterprise.enterpriseId,
        userId: user._id!,
        userEmail: user.email,
        userName: user.userName,
        policy: defaultGroup.value2,
        ownership: ownershipType,
        approvalStatus: JSON.stringify(approvalStatusOptions),
        sendEmail,
      };

      const resp = await dispatch(sendEnrollmentInvitation(payload));
      setEnrollmentDetails(resp.payload);
    } else if (deviceType === APPLE_OS) {
      if (!user._id || user._id === EMPTY_STRING) {
        dispatch(
          updateMessageApple({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a user',
          }),
        );
        return;
      }
      if (!defaultGroup._id || defaultGroup._id === EMPTY_STRING) {
        dispatch(
          updateMessageApple({
            ...messageInit,
            error: true,
            errorMessage: 'Please select a group',
          }),
        );
        return;
      }

      const payload: IAppleInvitationPayload = {
        groupId: defaultGroup._id,
        userId: user._id!,
        ownership: ownershipType,
        sendEmail,
        approvalStatus: JSON.stringify(approvalStatusOptions),
      };

      const resp = await dispatch(sendEnrollmentInvitation(payload));
      setEnrollmentDetails(resp.payload);
    }
  };
  const userList: ISelectItem[] = useMemo(
    () =>
      users.map((user) => ({
        _id: user._id as string,
        value1: user.email,
        value2: user.userName,
      })),
    [users],
  );

  const handleUserReset = () => {
    setUser(userInit);
  };

  useEffect(() => {
    if (initialUser.current.length === 0) {
      dispatch(getUsers()); // Fetch initial data on first render
    }

    return () => {
      dispatch(updateUsersArray([])); // cleaning the users state from userSlice so that users page logic remain intact
    };
  }, []);

  useEffect(() => {
    if (initialUser.current.length === 0) {
      initialUser.current = users; // We will store initial users from got during component mount so that if searchkey is empty we can use this initial users
    }
  }, [users]);

  useEffect(() => {
    if (searchKey !== EMPTY_STRING && user.email === EMPTY_STRING) {
      dispatch(setAll(true));
      dispatch(getUsers()); // this is for search key call.
    } else {
      dispatch(updateUsersArray(initialUser.current)); // if searchkey is empty then retain initial data to users state
    }
  }, [searchKey]);

  return (
    <Dialog fullWidth maxWidth='md' onClose={handleDialogWithReset} open={open}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label='close'
          onClick={handleDialogWithReset}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ minWidth: 120 }}>
          <Grid container direction='row' alignItems='center' paddingBottom={2}>
            <Grid item sm={4}>
              <Typography variant='body1'>User</Typography>
            </Grid>
            <Grid item sm={6}>
              <EnrollmentAutoComplete
                fieldId='user-select'
                loading={loading}
                entries={userList}
                selectedEntry={{
                  _id: user._id,
                  value1: user.email,
                  value2: user.userName,
                }}
                handeleReset={handleUserReset}
                handler={selectUser}
              />
            </Grid>
          </Grid>
          {/* temporary change */}
          {deviceType === ANDROID_OS && (
            <RadioOptions
              fieldId='device-ownership'
              fieldLabel='Device Owned By'
              options={[
                {
                  name: DEVICE_ENROLLMENT_CONST.DEVICE_PERSONAL,
                  label: 'Personal',
                  description: ANDROID_ENROLLMENT_MSG.PERSONAL_PROFILE_DESCRIPTION,
                },
                {
                  name: DEVICE_ENROLLMENT_CONST.DEVICE_CORPORATE,
                  label: 'Corporate',
                  description: ANDROID_ENROLLMENT_MSG.CORPORATE_PROFILE_DESCRIPTION,
                },
              ]}
              handler={handleOwnershipOption}
              selectedValue={ownershipType}
            />
          )}
          <Grid container direction='row' alignItems='center' paddingBottom={2}>
            <Grid item sm={4}>
              <Typography variant='body1'>Default Group</Typography>
            </Grid>
            <Grid item sm={6}>
              <EnrollmentAutoComplete
                fieldId='group-select'
                loading={loading}
                entries={groupList}
                selectedEntry={defaultGroup}
                handeleReset={() => setDefaultGroup(initialGroup)}
                handler={selectGroup}
              />
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='flex-start' paddingBottom={2}>
            <>
              <Grid item sm={4} sx={{ alignSelf: 'flex-start' }}>
                <Typography variant='body1'>User Verification</Typography>
              </Grid>
              <Grid item sm={6} sx={{ alignSelf: 'flex-start' }}>
                <Grid container direction='row'>
                  <Grid item sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleCheckBoxes}
                          name={DEVICE_ENROLLMENT_CONST.MFA_ENFORCEMENT}
                        />
                      }
                      label={
                        <Tooltip title={ANDROID_ENROLLMENT_MSG.MFA_FLOW_DESCRIPTION} arrow>
                          <span>Enforce MFA</span>
                        </Tooltip>
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <EnrollmentAutoComplete
                      fieldId='mfa-group-select'
                      loading={loading}
                      entries={groupList}
                      selectedEntry={{
                        _id: mfaGroup._id,
                        value1: mfaGroup.value1,
                        value2: mfaGroup.value2,
                      }}
                      handeleReset={() => setMFAGroup(initialGroup)}
                      handler={selectMFAGroup}
                    />
                  </Grid>
                  {showMFAAlert && (
                    <Grid item xs={12}>
                      <Alert severity='warning'>
                        {ANDROID_ENROLLMENT_MSG.MFA_SELECTED_WARNING_FOR_MINIORANGE_ENDUSER}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          </Grid>
          <Grid container direction='row' alignItems='flex-start' paddingBottom={2}>
            <>
              <Grid item sm={4} sx={{ alignSelf: 'flex-start' }}>
                <Typography variant='body1'>Admin Verification</Typography>
              </Grid>
              <Grid item sm={6} sx={{ alignSelf: 'flex-start' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckBoxes}
                      name={DEVICE_ENROLLMENT_CONST.ADMIN_APPROVAL_ENFORCEMENT}
                    />
                  }
                  label={
                    <Tooltip title={ANDROID_ENROLLMENT_MSG.ADMIN_APPROVAL_FLOW_DESCRIPTION} arrow>
                      <span>Admin Device approval</span>
                    </Tooltip>
                  }
                />
              </Grid>
            </>
          </Grid>
          <Grid container direction='row' alignItems='center' paddingBottom={2}>
            <Grid item sm={4} />
            {false && (
              <Grid item sm={8}>
                <Alert severity='warning' sx={{ marginBottom: 2 }}>
                  <AlertTitle>
                    {ANDROID_ENROLLMENT_MSG.ENROLLMENT_DEVICE_APPROVAL_DESCRIPTION}
                  </AlertTitle>
                </Alert>
              </Grid>
            )}
          </Grid>
          <RadioOptions
            fieldId='enrollment-type'
            fieldLabel='Enrollment'
            options={[
              {
                name: DEVICE_ENROLLMENT_CONST.CHOICE_SEND_EMAIL,
                label: 'By User Invite',
                description: ANDROID_ENROLLMENT_MSG.ENROLLMENT_EMAIL_OPTION_DESCRIPTION,
              },
              {
                name: DEVICE_ENROLLMENT_CONST.CHOICE_SHOW_DETAILS,
                label: 'By Myself',
                description: ANDROID_ENROLLMENT_MSG.ENROLLMENT_STEPS_DISPLAY_DESCRIPTION,
              },
            ]}
            handler={handleEnrollmentButton}
          />
        </Box>
        <div style={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            sx={{ m: 1 }}
            onClick={() =>
              sendEnrollmentInvite(enrollmentOption === DEVICE_ENROLLMENT_CONST.CHOICE_SEND_EMAIL)
            }
            disabled={
              !!enrollmentDetails.qrCode &&
              enrollmentOption === DEVICE_ENROLLMENT_CONST.CHOICE_SHOW_DETAILS
            }
          >
            {enrollmentButtonLabels[enrollmentOption]}
          </Button>
        </div>
        {enrollmentDetails.qrCode &&
          enrollmentOption === DEVICE_ENROLLMENT_CONST.CHOICE_SHOW_DETAILS && (
            <EnrollmentSteps
              deviceType={deviceType}
              qrCode={enrollmentDetails.qrCode}
              enrollmentToken={enrollmentDetails.enrollmentToken}
              steps={enrollmentDetails.steps}
            />
          )}
      </DialogContent>
    </Dialog>
  );
}

export default EnrollmentDialog;
