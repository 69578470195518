import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EastIcon from '@mui/icons-material/East';
import { useDispatch, useSelector } from 'react-redux';
import CustomHeader from '../../components/CustomHeader';
import MainLayout from '../../layouts/MainLayout';
import PropertyAndSelectInput from '../AndroidPolicies/components/PropertyAndSelectInput';
import { FONT_POPPINS } from '../../theme';
import {
  ANDROID_ESCALATION_FORM_NOTE,
  ANDROID_MDM_FAQ_LINK,
  ANDROID_MDM_GUIDES_LINK,
  IEscalationIssueCategoryEnum,
} from './constants';
import BugReportSteps from './BugReportSteps';
import { TextareaAutosizeStyled, customStyles } from './EscalationScreenCustomStyles';
import { messageInit } from '../../utils/common-constants';
import { AppDispatch, RootState } from '../../redux/store';
import {
  IAndroidEscalationFields,
  sendEscalation,
  updateMessage,
} from '../../redux/slices/androidEscalation/androidEscalationSlice';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import { Tooltip } from '../AndroidPolicies/components/InfoTooltip';

interface ICardProps {
  title: string;
  desc: string;
  link: string;
  actionTitle: string;
}
function CustomCard(props: ICardProps) {
  const { title, desc, link, actionTitle } = props;
  return (
    <Card sx={{ ...customStyles.card, marginTop: 5 }}>
      <CardContent>
        <Typography variant='h6' gutterBottom>
          {title}
        </Typography>
        <Typography variant='body2' paragraph>
          {desc}
        </Typography>
      </CardContent>
      <CardActions sx={customStyles.cardActions}>
        <Link sx={customStyles.link} target='_blank' href={link}>
          <EastIcon />
          &nbsp;{actionTitle}
        </Link>
      </CardActions>
    </Card>
  );
}

function AndroidEscalation() {
  const dispatch = useDispatch<AppDispatch>();

  const [openBugReportSteps, setBugReportSteps] = useState(false);
  const escalationFieldsInit: IAndroidEscalationFields = {
    category: '',
    issueDescription: '',
    documents: '',
  };
  const [escalationFields, setEscalationFields] = useState<IAndroidEscalationFields>(
    escalationFieldsInit,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const toggleBugReportSteps = (event: React.MouseEvent<HTMLButtonElement>, open: boolean) => {
    setAnchorEl(customHeaderRef.current);
    setBugReportSteps(open);
  };

  const handleClose = () => {
    setBugReportSteps(false);
  };

  const androidEscalation = useSelector((state: RootState) => state.androidEscalation);

  useEffect(() => {
    if (androidEscalation.message.success) {
      setEscalationFields(escalationFieldsInit);
    }
  }, [androidEscalation.message.success, dispatch]);

  const handleSubmit = () => {
    if (escalationFields.category?.trim() === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Please select the category of your issue',
        }),
      );
      return;
    }
    if (escalationFields.issueDescription?.trim() === '') {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Please describe the issue behaviour',
        }),
      );
      return;
    }

    const payload: IAndroidEscalationFields = {
      category:
        IEscalationIssueCategoryEnum[
          escalationFields.category as keyof typeof IEscalationIssueCategoryEnum
        ],
      issueDescription: escalationFields.issueDescription,
      documents: escalationFields.documents,
    };

    dispatch(sendEscalation(payload));
  };

  const customHeaderRef = useRef(null);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
  ) => {
    const { name, value } = event.target;
    setEscalationFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <MainLayout>
      <CustomHeader
        ref={customHeaderRef}
        title='Need Help'
        subtitle="Questions, Bug reports, Feedback - We're here for it all"
      />
      <Box>
        <Grid
          container
          justifyContent='space-between'
          sx={{ overflow: 'hidden', paddingY: 8, paddingX: 10 }}
        >
          <Grid item sm={7}>
            <PropertyAndSelectInput
              data={{
                property: 'Troubleshooting Category',
                name: 'category',
                menuItems: [
                  {
                    view: IEscalationIssueCategoryEnum.device_enrollment,
                    value: 'device_enrollment',
                  },
                  {
                    view: IEscalationIssueCategoryEnum.policy_troubleshooting,
                    value: 'policy_troubleshooting',
                  },
                  {
                    view: IEscalationIssueCategoryEnum.application_management,
                    value: 'application_management',
                  },
                  {
                    view: IEscalationIssueCategoryEnum.device_management,
                    value: 'device_management',
                  },
                  { view: IEscalationIssueCategoryEnum.other, value: 'other' },
                ],
                handleChange: (e: SelectChangeEvent) => handleInputChange(e),
                selectFieldLabel: 'Select Category',
                direction: 'column',
                width: '60%',
                isRequired: true,
                value: escalationFields.category,
              }}
            />
            <Typography variant='body1' paddingBottom={2}>
              Write Short summary of your issue <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextareaAutosizeStyled
              id='issueDescription'
              name='issueDescription'
              aria-label='summary'
              placeholder='Example: “How can I change work profile to personal”'
              style={{ width: '100%', fontFamily: FONT_POPPINS }}
              minRows={6}
              onChange={handleInputChange}
              value={escalationFields.issueDescription}
            />
            <Typography variant='body1' paddingBottom={2} marginTop={4} alignContent='center'>
              Speed up debugging by providing supporting documents like dashboard screenshots,
              device screenshots, and device bug reports
              <Tooltip sx={{ marginLeft: 2 }} title={ANDROID_ESCALATION_FORM_NOTE}>
                <InfoIcon />
              </Tooltip>
            </Typography>
            <TextField
              fullWidth
              type='text'
              size='small'
              id='documents'
              name='documents'
              placeholder='Enter Drive Link (Eg. https://docs.google.com/document/d)'
              variant='outlined'
              onChange={handleInputChange}
              value={escalationFields.documents}
              sx={{ marginBottom: 4 }}
            />
            <Button variant='contained' onClick={handleSubmit} sx={{ marginRight: 4 }}>
              Submit Issue
            </Button>
            <Button variant='outlined' onClick={(e) => toggleBugReportSteps(e, true)}>
              Bug Report Steps
            </Button>{' '}
          </Grid>

          <Grid item sm={4}>
            <CustomCard
              title='Documentation'
              desc='We offer comprehensive documentation to guide you through every step.'
              link={ANDROID_MDM_GUIDES_LINK}
              actionTitle='View Guides'
            />
            <CustomCard
              title='Common Questions'
              desc='Explore our FAQs for detailed information and answers to common questions.'
              link={ANDROID_MDM_FAQ_LINK}
              actionTitle='View FAQ`s'
            />
          </Grid>
        </Grid>
        <BugReportSteps
          openBugReportSteps={openBugReportSteps}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </Box>
      {(androidEscalation.message.error || androidEscalation.message.success) && (
        <ErrorSuccessSnackbar
          opened={androidEscalation.message.error || androidEscalation.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={androidEscalation.message.errorMessage}
          successMessage={androidEscalation.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AndroidEscalation;
