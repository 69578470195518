import { Grid, GridProps, Typography } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../..';
import CustomMultiSelectInput from '../../../../components/CustomMultiSelectInput';
import CustomSelectInput from '../../../../components/CustomSelectInput';
import AppleMinimumVersion from '../../../AppleProfiles/AppleMinimumVersion';
import AndroidMinimumVersion from '../AndroidMinimumVersion';
import InfoTooltip from '../InfoTooltip';
import ProfileType from '../ProfileType';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function PropertyAndSelectInput(props: any) {
  const { policy } = useContext(PolicyContext);
  const { data } = props;
  const {
    property,
    name,
    menuItems,
    value,
    handleChange,
    direction,
    width,
    androidVersion,
    profileType,
    infoText,
    selectFieldLabel,
    isRequired,
    isDisabled,
    appleSupport,
    isMultiSelect,
    propertyWidth,
    inputWidth,
  } = data;

  const gridContainerProps: GridProps =
    direction === 'column'
      ? {
          direction: 'column',
          paddingBottom: 4,
        }
      : {
          direction: 'row',
          paddingBottom: 2,
          alignItems: 'center',
        };

  const gridItemLabelProps: GridProps =
    direction === 'column'
      ? {
          paddingBottom: 2,
        }
      : {};

  const gridItemInputProps: GridProps =
    direction === 'column'
      ? {
          width: width || '60%',
        }
      : {};

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Grid container {...gridContainerProps}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Grid item sm={propertyWidth ?? 6} {...gridItemLabelProps}>
        <Typography variant='body1'>
          {property} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </Typography>
        <div className='icon-container'>
          {androidVersion && <AndroidMinimumVersion androidVersion={androidVersion} />}
          {profileType && <ProfileType profileType={profileType} />}
          {infoText && <InfoTooltip infoText={infoText} />}
        </div>
        <div className='icon-container'>
          {appleSupport && <AppleMinimumVersion appleVersion={appleSupport.version} />}
          {appleSupport && appleSupport.isSupervised && (
            <ProfileType
              profileType={`Supported on Supervised devices ${
                appleSupport.supervisionOver ? `above ${appleSupport.supervisionOver}` : ''
              }`}
            />
          )}
        </div>
      </Grid>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Grid item sm={inputWidth ?? 6} {...gridItemInputProps}>
        {!isMultiSelect ? (
          <CustomSelectInput
            name={name}
            menuItems={menuItems}
            value={value}
            handleChange={handleChange}
            disabled={
              policy.policyName === process.env.REACT_APP_DEFAULT_ANDROID_POLICY_NAME || isDisabled
            }
            label={selectFieldLabel}
          />
        ) : (
          <CustomMultiSelectInput
            name={name}
            menuItems={menuItems}
            value={value}
            handleChange={handleChange}
            disabled={policy.policyName === process.env.REACT_APP_DEFAULT_ANDROID_POLICY_NAME}
            label={selectFieldLabel}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default PropertyAndSelectInput;
