import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EMPTY_STRING, HTTP_STATUS_CODE } from '../../../constants';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  OAUTH2_CONFIG_GET_ROUTE,
  OAUTH2_CONFIG_TEST_ROUTE,
  OAUTH2_CONFIG_UPDATE_ROUTE,
} from '../../../utils/routes-defs';

export interface IOAuthConfig {
  name: string;
  clientId: string;
  clientSecret: string;
  scope: string;
  authorizationUrl: string;
  tokenUrl: string;
  userInfoUrl: string;
  userClaimMapping: string;
}
export const oAuthConfigInit: IOAuthConfig = {
  name: EMPTY_STRING,
  clientId: EMPTY_STRING,
  clientSecret: EMPTY_STRING,
  scope: EMPTY_STRING,
  authorizationUrl: EMPTY_STRING,
  tokenUrl: EMPTY_STRING,
  userInfoUrl: EMPTY_STRING,
  userClaimMapping: JSON.stringify({
    name: 'name',
    email: 'email',
  }),
};

interface IOAuthConfigState {
  oAuthConfig: IOAuthConfig;
  redirectUri: string;
  loading: boolean;
  message: IMessage;
}

const initialState: IOAuthConfigState = {
  oAuthConfig: oAuthConfigInit,
  redirectUri: EMPTY_STRING,
  loading: false,
  message: messageInit,
};

export const getOAuthConfig = createAsyncThunk(
  'oAuthConfig/getOAuthConfig',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(OAUTH2_CONFIG_GET_ROUTE);
      return response.data;

      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const updateOAuthConfig = createAsyncThunk(
  'oAuthConfig/updateOAuthConfig',
  async (payload: IOAuthConfig, thunkAPI) => {
    try {
      const response = await axiosInstance().post(OAUTH2_CONFIG_UPDATE_ROUTE, payload);
      return response.data;

      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const testOAuthConfig = createAsyncThunk(
  'oAuthConfig/testOAuthConfig',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance().get(OAUTH2_CONFIG_TEST_ROUTE);
      console.log(response);
      const url = response.data;
      const windowName = 'NewWindow';
      const windowSize = 'width=800,height=600';
      window.open(url, windowName, windowSize) as Window;

      return response.data;

      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

const oAuthconfigSlice = createSlice({
  name: 'appleProfile',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get config
    builder.addCase(getOAuthConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOAuthConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.oAuthConfig = {
        ...oAuthConfigInit,
        ...action.payload.oAuthConfig,
        userClaimMapping: JSON.stringify(action.payload.oAuthConfig.userClaimMapping),
      };
      state.redirectUri = action.payload.redirectUri;
    });
    builder.addCase(getOAuthConfig.rejected, (state, action) => {
      const message = action.payload as string;
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message,
      };
    });

    // update config
    builder.addCase(updateOAuthConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOAuthConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.oAuthConfig = {
        ...oAuthConfigInit,
        ...action.payload.oAuthConfig,
        userClaimMapping: JSON.stringify(action.payload.oAuthConfig.userClaimMapping),
      };
      state.redirectUri = action.payload.redirectUri;

      state.message = {
        ...messageInit,
        success: true,
        successMessage: action.payload.message,
      };
    });
    builder.addCase(updateOAuthConfig.rejected, (state, action) => {
      const message = action.payload as string;
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message,
      };
    });

    // test config
    builder.addCase(testOAuthConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(testOAuthConfig.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(testOAuthConfig.rejected, (state, action) => {
      const message = action.payload as string;
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message,
      };
    });
  },
});

export const { updateMessage } = oAuthconfigSlice.actions;
export default oAuthconfigSlice;
