import {
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndInput from '../../PropertyAndInput';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';
import { ANDROID_POLICY_CONST } from '../../../../../constants';
import { ANDROID_POLICY_MSG } from '../../../../../messages';
import { IPasswordSetting } from '../../../definitions';

function PasswordQualitySuggestion({ suggestionText }: { suggestionText: string }) {
  const color = [
    ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS.COMPLEXITY_LOW,
    ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS.COMPLEXITY_MEDIUM,
    ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS.COMPLEXITY_HIGH,
  ].includes(suggestionText)
    ? 'green'
    : 'red';

  return (
    <Typography component='span' sx={{ color, fontSize: '0.60rem', fontWeight: 'bold' }}>
      <sup>{suggestionText}</sup>
    </Typography>
  );
}

function PasswordSettings() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { passwordSettings } = policy;

  const handleChange = (event: SelectChangeEvent, index: number) => {
    const { name, value } = event.target;

    setPolicy((prev) => {
      const newPasswordSettings = prev.passwordSettings.map((elem, id) =>
        id === index
          ? {
              ...elem,
              [name]: value,
            }
          : elem,
      );
      return {
        ...prev,
        passwordSettings: newPasswordSettings,
      };
    });
  };

  const handleAddNewPasswordPolicy = () => {
    setPolicy((prev) => {
      const newSetting = {
        minLength: 6,
        minLetters: 0,
        minLowerCase: 0,
        minimumNonLetter: 0,
        minimumNumbers: 0,
        minimumSymbols: 0,
        minimumUpperCase: 0,
        quality: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC,
        historyLength: 2,
        maxifailedPasswords: 4,
        expirationTimeout: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.NEVER,
        scope: ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE,
        requireUnlock: ANDROID_POLICY_CONST.PASSWORD.REQUIRE_PASSWORD_UNLOCK.DEFAULT,
        unifiedLockSettings: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.COMMON,
      };

      return {
        ...prev,
        passwordSettings: [...passwordSettings, newSetting],
      };
    });
  };

  const removePasswordSetting = (index: number) => {
    setPolicy((prev) => {
      const newPasswordSettings = prev.passwordSettings.filter((_, id) => id !== index);

      return {
        ...prev,
        passwordSettings: newPasswordSettings,
      };
    });
  };

  // The function handles suggestions based on the scope
  const handlePasswordQualitySuggestion = (passwordSetting: IPasswordSetting, value: string) =>
    passwordSetting.quality !== value &&
    passwordSetting.scope !== ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE;

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={6}>
        <Grid>
          {passwordSettings.map((passwordSetting, index) => (
            <Card sx={{ padding: 2, marginBottom: 2, display: 'flex', flexDirection: 'column' }}>
              <PropertyAndSelectInput
                data={{
                  property: 'Profile Scope',
                  name: 'scope',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.SCOPE,
                  menuItems: [
                    {
                      view: 'Work Profile',
                      value: ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE,
                      tooltip: ANDROID_POLICY_MSG.COMMON_SCOPES.WORK_PROFILE,
                    },
                    {
                      view: 'Device Wide',
                      value: ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.DEVICE_WIDE,
                      tooltip: ANDROID_POLICY_MSG.COMMON_SCOPES.DEVICE_WIDE,
                    },
                  ],
                  value: passwordSetting.scope,
                  androidVersion: '(Device Password)5.0+ (Work Profile Password)7.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Password Quality',
                  name: 'quality',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.QUALITY,
                  menuItems: [
                    {
                      view: (
                        <>
                          No Password{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.UNSPECIFIED,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS
                                  .UNSPECIFIED
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.UNSPECIFIED,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.NO_PASSWORD,
                    },
                    {
                      view: (
                        <>
                          Complex{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS.COMPLEX
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.COMPLEX,
                    },
                    {
                      view: (
                        <>
                          Complexity Low{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_LOW,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS
                                  .COMPLEXITY_LOW
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_LOW,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.COMPLEXITY_LOW,
                      isDisabled:
                        passwordSetting.scope ===
                        ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE,
                    },
                    {
                      view: (
                        <>
                          Complexity Medium{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_MEDIUM,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS
                                  .COMPLEXITY_MEDIUM
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_MEDIUM,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.COMPLEXITY_MEDIUM,
                      isDisabled:
                        passwordSetting.scope ===
                        ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE,
                    },
                    {
                      view: (
                        <>
                          Complexity High{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_HIGH,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS
                                  .COMPLEXITY_HIGH
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_HIGH,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.COMPLEXITY_HIGH,
                      isDisabled:
                        passwordSetting.scope ===
                        ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE,
                    },
                    {
                      view: (
                        <>
                          Numeric{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS.NUMERIC
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.NUMERIC,
                    },
                    {
                      view: (
                        <>
                          Numeric Complex{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC_COMPLEX,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS
                                  .NUMERIC_COMPLEX
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC_COMPLEX,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.NUMERIC_COMPLEX,
                    },
                    {
                      view: (
                        <>
                          Alphabetic{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.ALPHABETIC,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS
                                  .ALPHABETIC
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.ALPHABETIC,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.ALPHABETIC,
                    },
                    {
                      view: (
                        <>
                          Alphanumeric{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.ALPHANUMERIC,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS
                                  .ALPHANUMERIC
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.ALPHANUMERIC,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.ALPHANUMERIC,
                    },
                    {
                      view: (
                        <>
                          Biometric Weak{' '}
                          {handlePasswordQualitySuggestion(
                            passwordSetting,
                            ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.WEAK,
                          ) && (
                            <PasswordQualitySuggestion
                              suggestionText={
                                ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY_SUGGESTIONS.WEAK
                              }
                            />
                          )}
                        </>
                      ),
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.WEAK,
                      tooltip: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_TOOLTIP.BIOMETRIC_WEAK,
                    },
                  ],
                  value: passwordSetting.quality,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndInput
                property='Minimum length of password'
                name='minLength'
                type='number'
                value={passwordSetting.minLength}
                disabled={
                  passwordSetting.quality === 'unspecified' ||
                  [
                    ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_LOW,
                    ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_MEDIUM,
                    ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEXITY_HIGH,
                  ].includes(passwordSetting.quality)
                }
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of numeric digits'
                name='minimumNumbers'
                type='number'
                value={passwordSetting.minimumNumbers}
                disabled={
                  passwordSetting.quality !== ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX
                }
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of letters'
                name='minLetters'
                type='number'
                value={passwordSetting.minLetters}
                disabled={
                  passwordSetting.quality !== ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX
                }
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of lowercase letters'
                name='minLowerCase'
                type='number'
                value={passwordSetting.minLowerCase}
                disabled={
                  passwordSetting.quality !== ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX
                }
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of uppercase letters'
                name='minimumUpperCase'
                type='number'
                value={passwordSetting.minimumUpperCase}
                disabled={
                  passwordSetting.quality !== ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX
                }
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of symbols'
                name='minimumSymbols'
                type='number'
                value={passwordSetting.minimumSymbols}
                disabled={
                  passwordSetting.quality !== ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX
                }
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Password History'
                name='historyLength'
                type='number'
                infoText={ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.PASSWORD_HISTORY}
                value={passwordSetting.historyLength}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />

              <PropertyAndSelectInput
                data={{
                  property: 'Password Expiry',
                  name: 'expirationTimeout',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.PASSWORD_EXPIRY,
                  menuItems: [
                    { view: 'Never', value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.NEVER },
                    {
                      view: '1 Year',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.ONE_YEAR,
                    },
                    {
                      view: '6 Months',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.SIX_MONTHS,
                    },
                    {
                      view: '3 Months',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.THREE_MONTHS,
                    },
                    {
                      view: '1 Month',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.ONE_MONTHS,
                    },
                  ],
                  value: passwordSetting.expirationTimeout,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndInput
                property='Maximum Failed Passwords'
                name='maxifailedPasswords'
                type='number'
                infoText={ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.MAX_FAIL_PASSWORDS}
                value={passwordSetting.maxifailedPasswords}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Require Password Unlock',
                  name: 'requireUnlock',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.UNIFIED_LOCK_SETTINGS,
                  menuItems: [
                    {
                      view: 'Device Default',
                      value: ANDROID_POLICY_CONST.PASSWORD.REQUIRE_PASSWORD_UNLOCK.DEFAULT,
                    },
                    {
                      view: 'Everyday',
                      value: ANDROID_POLICY_CONST.PASSWORD.REQUIRE_PASSWORD_UNLOCK.EVERYDAY,
                    },
                  ],
                  value: passwordSetting.requireUnlock,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Device & Work Profile Lock Settings',
                  name: 'unifiedLockSettings',
                  menuItems: [
                    {
                      view: 'Common Lock',
                      value: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.COMMON,
                      tooltip:
                        ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.UNIFIED_LOCK_SETTINGS_TOOLTIP
                          .COMMON_LOCK,
                    },
                    {
                      view: 'Separate Lock',
                      value: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.SEPERATE,
                      tooltip:
                        ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.UNIFIED_LOCK_SETTINGS_TOOLTIP
                          .SEPERATE_LOCK,
                    },
                  ],
                  value:
                    passwordSetting.scope !==
                    ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.DEVICE_WIDE
                      ? passwordSetting.unifiedLockSettings
                      : '',
                  isDisabled:
                    passwordSetting.scope ===
                    ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.DEVICE_WIDE,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                  infoText: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.TOOLTIP_MESSAGE,
                }}
              />
              <div style={{ marginTop: 'auto', width: 'fit-content', marginLeft: 'auto' }}>
                <Button
                  onClick={() => removePasswordSetting(index)}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))}
          <Button variant='contained' onClick={handleAddNewPasswordPolicy}>
            Add Password Settings For Different Profile Scope
          </Button>
        </Grid>
      </Grid>
      <Grid item sm={6}>
        <Typography fontWeight='bold'> Password Quality Instructions</Typography>
        If the password quality is set to Complexity Low, Medium, or High, an additional fallback
        object must be added
        <List>
          <Typography fontWeight='bold'> BYOD</Typography>
          {ANDROID_POLICY_MSG.PASSWORD_QUALITY_INSTRUCTIONS.BYOD.map((instructions) => (
            <ListItem>
              <ListItemText primary={instructions} />
            </ListItem>
          ))}
        </List>
        <List>
          <Typography fontWeight='bold'> COMPANY OWNED</Typography>
          {ANDROID_POLICY_MSG.PASSWORD_QUALITY_INSTRUCTIONS.COMANY_OWNED.map((instructions) => (
            <ListItem>
              <ListItemText primary={instructions} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default PasswordSettings;
