import { ANDROID_POLICY_MSG } from '../../messages';

export interface IAndroidPolicy {
  policyId?: string;
  policyName: string;
  policyDescription: string;
  appCatalog: IAppCatalog;
  passwordSettings: IPasswordSetting[];
  deviceFunctionality: IDeviceFunctionality;
  dataSharing: IDataSharing;
  securityPermissions: ISecurityPermissions;
  systemUpdate: ISystemUpdate;
  network: INetwork;
  customPermissions: ICustomPermissions;
  supportMessages: ISupportMessages;
}

export interface IPermissionGrant {
  selected?: boolean;
  permission: string;
  policy: string;
}
export interface IApplicationMeta {
  title: string;
  managedConfiguration: boolean;
  iconUrl: string;
  permissions: [
    {
      permissionId: string;
      name?: string;
      description?: string;
    },
  ];
}
export interface IAndroidApp {
  appname: string;
  appInstallationType: string;
  appPolicy: string;
  appGrants: IPermissionGrant[];
  appDisabled: boolean;
  appMiniVersion: number;
  appScopes: string[];
  appManagedConfiguration?: {
    appConfigTemplate: string;
  };
  appTrackIds?: string[];
  appConnectedWorkAndPersonal: string;
  appUpdateMode: string;
  appOnVpnLockdownExemption?: string;
  appWidgets: string;
  appMeta: IApplicationMeta;
}
export interface IAppCatalog {
  applications: IAndroidApp[];
  appAutoUpdatePolicy: string;
  allAppsPolicy: string;
  allAppsPermissionGrant: IPermissionGrant[];
  untrustedApps: string;
  playProtectVerifyApps: string;
}
export interface IPasswordSetting {
  minLength: number;
  minLetters: number;
  minLowerCase: number;
  minimumNonLetter: number;
  minimumNumbers: number;
  minimumSymbols: number;
  minimumUpperCase: number;
  quality: string;
  historyLength: number;
  maxifailedPasswords: number;
  expirationTimeout: string;
  scope: string;
  requireUnlock: string;
  unifiedLockSettings: string;
}
export interface IDeviceFunctionality {
  screenCapturePermission: boolean;
  physicalMediaPermission: boolean;
  cameraPermission: string;
  microphonePermission: string;
}
export interface IDeviceConnectivitySettings {
  usbAccess: string;
  bluetoothDisabled: boolean;
  printingPolicy: string;
}
export interface IDataSharing {
  workContactsScope: string;
  copyPasteScope: string;
  dataSharingScope: string;
  widgetsDefaultScope: string;
  deviceConnectivitySettings: IDeviceConnectivitySettings;
}
export interface IPolicyEnforcementBlockAction {
  blockAfterDays: number;
  blockScope: string;
}
export interface IPolicyEnforcementWipeAction {
  wipeAfterDays: number;
  preserveFrp: boolean;
}
export interface IPolicyEnforcementRule {
  blockAction: IPolicyEnforcementBlockAction;
  wipeAction: IPolicyEnforcementWipeAction;
  settingName: string;
}
export interface ISecurityPermissions {
  developerSettingsScope: string;
  factoryResetSetting: boolean;
  frpAdminEmails: string[];
  modifyAccountsDisabled: boolean;
  keyguardDisabled: boolean;
  keyguardDisabledFeatures: string[];
  policyEnforcementRules: IPolicyEnforcementRule[];
  accountTypesWithManagementDisabled: string[];
}

export interface ISystemUpdateFreezePeriod {
  startDate: {
    year: '0';
    month: string;
    day: string;
  };
  endDate: {
    year: '0';
    month: string;
    day: string;
  };
}
export interface ISystemUpdate {
  type: string;
  startMinutes?: string;
  endMinutes?: string;
  freezePeriods?: ISystemUpdateFreezePeriod[];
}

export interface IEAP {
  eapInner: string;
  eapOuter: string;
  eapIdentity: string;
  eapDomainSuffixMatch: string[];
  eapServerCARef?: string;
  eapClientCertKeyPairAlias?: string;
  eapClientCertType: string;
  eapClientCertRef: string;
}
export interface IWifi {
  ssid: string;
  eap: IEAP;
  security: string;
  autoconnect: boolean;
  passphrase: string;
  macAddressRandomizationMode: string;
}
export interface INetworkConfigurations {
  guid: string;
  name: string;
  type: string;
  wifi: IWifi;
}
export interface ICertificates {
  guid?: string;
  type?: string;
  x509?: string;
  pkcs12?: string;
}
export interface IopenNetworkConfiguration {
  networkConfigurations: INetworkConfigurations[];
  certificates: ICertificates[];
  wifiConfigDisabled: boolean;
}

export interface INetwork {
  vpn: {
    vpnConfigDisabled: boolean;
    alwaysOnVpnPackage: {
      packageName: string;
      lockdownEnabled: boolean;
    };
  };
  openNetworkConfiguration: IopenNetworkConfiguration;
}
export interface ITimeRestriction {
  enable: boolean;
  startTime: string; // UTC => HH:MM
  endTime: string; // UTC => HH:MM
  timezone: string; // actual timezone
  offset: string; // offset in the format HH:MM from UTC
}
export interface ICustomPermissions {
  timeRestriction: ITimeRestriction;
}

interface localizedMessage {
  [languagecode: string]: string;
}

export interface IUserFacingFrontendMessage {
  defaultMessage: string;
  localizedMessages: localizedMessage;
}

export interface ISupportMessages {
  shortSupportMessage: IUserFacingFrontendMessage;
  longSupportMessage: IUserFacingFrontendMessage;
}
export const initAndroidPolicy: IAndroidPolicy = {
  policyName: '',
  policyDescription: '',
  appCatalog: {
    applications: [],
    appAutoUpdatePolicy: 'always',
    allAppsPolicy: 'prompt',
    allAppsPermissionGrant: [],
    untrustedApps: 'allow_device_wide',
    playProtectVerifyApps: 'enforced',
  },
  passwordSettings: [
    {
      minLength: 6,
      minLetters: 0,
      minLowerCase: 0,
      minimumNonLetter: 0,
      minimumNumbers: 0,
      minimumSymbols: 0,
      minimumUpperCase: 0,
      quality: 'numeric',
      historyLength: 2,
      maxifailedPasswords: 4,
      expirationTimeout: '123456789.0s',
      scope: 'scope_profile',
      requireUnlock: 'default',
      unifiedLockSettings: 'unified_lock',
    },
  ],
  deviceFunctionality: {
    screenCapturePermission: true,
    physicalMediaPermission: true,
    cameraPermission: 'enforced',
    microphonePermission: 'enforced',
  },
  dataSharing: {
    workContactsScope: 'allowed',
    copyPasteScope: 'allowed',
    dataSharingScope: 'allowed',
    widgetsDefaultScope: 'allowed',
    deviceConnectivitySettings: {
      usbAccess: 'allowed',
      bluetoothDisabled: true,
      printingPolicy: 'allowed',
    },
  },
  securityPermissions: {
    developerSettingsScope: 'disabled',
    factoryResetSetting: true,
    frpAdminEmails: [],
    modifyAccountsDisabled: false,
    keyguardDisabled: false,
    keyguardDisabledFeatures: [],
    policyEnforcementRules: [],
    accountTypesWithManagementDisabled: [],
  },
  systemUpdate: {
    type: 'postpone',
    startMinutes: '00:00',
    endMinutes: '04:00',
    freezePeriods: [],
  },
  network: {
    vpn: {
      vpnConfigDisabled: false,
      alwaysOnVpnPackage: {
        packageName: '',
        lockdownEnabled: false,
      },
    },
    openNetworkConfiguration: {
      networkConfigurations: [],
      certificates: [],
      wifiConfigDisabled: true,
    },
  },
  customPermissions: {
    timeRestriction: {
      enable: false,
      startTime: '17:00', // UTC => HH:MM
      endTime: '09:00', // UTC => HH:MM
      timezone: '(GMT +5:30) Indian Standard (Mumbai, Kolkata, Chennai, New Delhi)', // actual timezone
      offset: '+05:30', // offset in the format HH:MM from UTC
    },
  },
  supportMessages: {
    shortSupportMessage: {
      defaultMessage: ANDROID_POLICY_MSG.SHORTSUPPORT_DEFAULT_MESSAGE,
      localizedMessages: {
        en: ANDROID_POLICY_MSG.SHORTSUPPORT_DEFAULT_MESSAGE,
      },
    },
    longSupportMessage: {
      defaultMessage: ANDROID_POLICY_MSG.LONGSUPPORT_DEFAULT_MESSAGE,
      localizedMessages: {
        en: ANDROID_POLICY_MSG.LONGSUPPORT_DEFAULT_MESSAGE,
      },
    },
  },
};
