/* eslint-disable react/jsx-props-no-spreading */
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Tooltip } from '../../pages/AndroidPolicies/components/InfoTooltip';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function CustomSelectInput(props: any) {
  const { name, label, menuItems, value, handleChange, ...restProps } = props;

  return (
    <FormControl fullWidth size='small'>
      <InputLabel id={name}>{label || 'Select'}</InputLabel>
      <Select
        labelId={name}
        size='small'
        label={label || 'Select'}
        name={name}
        value={value}
        onChange={handleChange}
        {...restProps}
      >
        {menuItems.map((item: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={`${item.view} ${index}`} value={item.value} disabled={item.isDisabled}>
            {item.tooltip ? (
              <Tooltip
                title={item.tooltip}
                placement='right-start'
                disableHoverListener={!item.tooltip}
              >
                <Typography width='100%' variant='body1' color='text.secondary'>
                  {item.view}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant='body1' color='text.secondary'>
                {item.view}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomSelectInput;
