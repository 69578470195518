import { configureStore } from '@reduxjs/toolkit';
import androidDeviceSlice from './slices/androidDevice/androidDeviceSlice';
import androidEnterpriseSlice from './slices/androidEnterprise/androidEnterpriseSlice';
import adnroidEscalationSlice from './slices/androidEscalation/androidEscalationSlice';
import androidPolicySlice from './slices/androidPolicy/androidPolicySlice';
import appleAppGroupSlice from './slices/appleAppGroup/appleAppGroupSlice';
import appleDeviceSlice from './slices/appleDevice/appleDeviceSlice';
import appleDeviceGroupSlice from './slices/appleDeviceGroup/appleDeviceGroupSlice';
import appleEnrollmentSlice from './slices/appleEnrollment/appleEnrollmentSlice';
import appleProfileSlice from './slices/appleProfile/appleProfileSlice';
import authSlice from './slices/auth/authSlice';
import deviceGroupSlice from './slices/deviceGroup/deviceGroupSlice';
import oAuthconfigSlice from './slices/manage/oauthSlice';
import usersSlice from './slices/users/usersSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: usersSlice.reducer,
    deviceGroup: deviceGroupSlice.reducer,
    androidEnterprise: androidEnterpriseSlice.reducer,
    androidDevice: androidDeviceSlice.reducer,
    androidPolicy: androidPolicySlice.reducer,
    androidEscalation: adnroidEscalationSlice.reducer,
    appleEnrollment: appleEnrollmentSlice.reducer,
    appleDevice: appleDeviceSlice.reducer,
    appleDeviceGroup: appleDeviceGroupSlice.reducer,
    appleProfile: appleProfileSlice.reducer,
    appleAppGroup: appleAppGroupSlice.reducer,
    oAuthConfig: oAuthconfigSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
