export const EMPTY_STRING = '';
export const NULL = null;
export const UNDEFINED = undefined;
export const NOT_FOUND = 'NOT FOUND';
export const HTTP_STATUS_CODE = {
  FORBIDDEN: 403,
};
export const GLOBAL_CONST = {
  SELECT_ALLOW_DENY_TRUE_FALSE: [
    { view: 'Allow', value: true },
    { view: 'Deny', value: false },
  ],
  SELECT_YES_NO_TRUE_FALSE: [
    { view: 'Yes', value: true },
    { view: 'No', value: false },
  ],
};

export const DEVICE_ENROLLMENT_CONST = {
  CHOICE_SEND_EMAIL: 'send-email',
  CHOICE_SHOW_DETAILS: 'show-details',
  DEVICE_PERSONAL: 'personal',
  DEVICE_CORPORATE: 'corporate',
  MFA_ENFORCEMENT: 'needMFA',
  ADMIN_APPROVAL_ENFORCEMENT: 'needAdminApproval',
};

export const ANDROID_DEVICE_GROUP_CONST = {};

export const ANDROID_DEVICE_CONST = {
  COMPANY_OWNED: 'DEVICE_OWNER',
  BYOD: 'PROFILE_OWNER',
};

export const ANDROID_POLICY_CONST = {
  USER_FACING_MESSAGE_MAXLENGTH: 200,
  OS_UPDATE: {
    AUTOMATIC: 'automatic',
    WINDOWED: 'windowed',
    POSTPONE: 'postpone',
  },
  PASSWORD: {
    PROFILE_SCOPE: {
      WORK_PROFILE: 'scope_profile',
      DEVICE_WIDE: 'scope_device',
    },
    PASSWORD_QUALITY: {
      UNSPECIFIED: 'unspecified',
      COMPLEX: 'complex',
      NUMERIC: 'numeric',
      NUMERIC_COMPLEX: 'numeric_complex',
      ALPHABETIC: 'alphabetic',
      ALPHANUMERIC: 'alphanumeric',
      WEAK: 'weak',
      COMPLEXITY_LOW: 'complexity_low',
      COMPLEXITY_MEDIUM: 'complexity_medium',
      COMPLEXITY_HIGH: 'complexity_high',
    },
    PASSWORD_QUALITY_SUGGESTIONS: {
      UNSPECIFIED: 'Not Recomended',
      COMPLEX: 'Legacy',
      NUMERIC: 'Legacy',
      NUMERIC_COMPLEX: 'Legacy',
      ALPHABETIC: 'Legacy',
      ALPHANUMERIC: 'Legacy',
      WEAK: 'Legacy',
      COMPLEXITY_LOW: 'Recomended',
      COMPLEXITY_MEDIUM: 'Recomended',
      COMPLEXITY_HIGH: 'Recomended',
    },
    PASSWORD_EXPIRY: {
      NEVER: '123456789.0s',
      ONE_YEAR: '31536000.0s',
      SIX_MONTHS: '15768000.0s',
      THREE_MONTHS: '7884000.0s',
      ONE_MONTHS: '2628000.0s',
    },
    REQUIRE_PASSWORD_UNLOCK: {
      DEFAULT: 'default',
      EVERYDAY: 'require_every_day',
    },
    LOCK_SETTINGS: {
      COMMON: 'unified_lock',
      SEPERATE: 'separate_lock',
      TOOLTIP_MESSAGE:
        'If the Profile Scope is Device Wide, then the Device and Work Profile Lock settings are disabled.',
    },
  },
};

export const APPLE_ENROLLMENT_CONST = {
  PAGE_HEADER: 'Apple Enrollment',

  APPLE_SIGNUP_URL: 'https://appleid.apple.com/account',
  APPLE_APN_PORTAL_URL:
    'https://idmsa.apple.com/IDMSWebAuth/signin?appIdKey=3fbfc9ad8dfedeb78be1d37f6458e72adc3160d1ad5b323a9e5c5eb2f8e7e3e2&rv=2',

  APNS_DEFAULT_REMAINING_DAYS: 366,
  APNS_REMAINING_DAYS_THREE_MONTHS: 30,
  APNS_DEFAULT_VALIDITY: NULL,
};

export const APPLE_DEVICE_GROUP_CONST = {};

export const APPLE_DEVICE_CONST = {
  PAGE_HEADER: 'Apple Devices',

  VPN_ACTIVATE: 'Activate',
  VPN_INITIATED: 'Initiated',
  VPN_ACTIVATED: 'Activated',
  VPN_RETRY: 'Retry',

  DEVICE_ACTIVE: 'Active',
  DEVICE_INACTIVE: 'Inactive',
};

export const APPLE_PROFILE_CONST = {
  PAGE_HEADER: 'Apple Profiles',

  MO_VPN_NAME: 'MO-VPN',
};

export const APPLE_APPLICATION_CONST = {
  UPLOAD_XML_BUTTON_TEXT: 'Upload XML',
  DOWNLOAD_XML_BUTTON_TEXT: 'Download XML',
};

export const APPLE_OS_TITLES = {
  ios: 'iOS',
  macos: 'macOS',
  tvos: 'tvOS',
  watchos: 'watchOS',
};
export const APPLE_OS_TYPES = {
  ios: 'ios',
  macos: 'macos',
  tvos: 'tvos',
  watchos: 'watchos',
};
export const USER_CONST = {
  EXCEL_SHEET_EMAIL_HEADER: 'Email',
  EXCEL_SHEET_USERNAME_HEADER: 'Username',
  EXCEL_SHEET_INVALID_EMAIL_DIALOG_BOX: 'Invalid Emails Found',
  ADD_USER_BUTTON_TEXT: 'Add User',
  BULK_IMPORT_BUTTON_TEXT: 'Bulk Import',
  BULK_IMPORT_USER_DIALOG_TEXT: 'Import Users',
  BULK_USER_SAMPLE_FILE_DOWNLOAD_DIALOG_TEXT: 'Download sample file',
  SYNC_USERS_BUTTON_TEXT: 'Sync Users',
};
export const ANDROID_OS = 'android';
export const APPLE_OS = 'apple';

export const OAUTH2_SERVER_NAME = {
  GOOGLE: 'google',
  MICROSOFT_ENTRA_ID: 'microsoft_entra_id',
  CUSTOM_IDP: 'custom-idp',
};
