import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAppleEnrollment, initAppleEnrollment } from '../..';
import FileDownloadButton from '../../../../components/FileDownloadButton';
import FileUploadButton, { getExtension } from '../../../../components/FileUploadButton';
import { APPLE_ENROLLMENT_CONST, EMPTY_STRING } from '../../../../constants';
import { APPLE_ENROLLMENT_MSG } from '../../../../messages';
import {
  createAppleEnrollment,
  updateMessage,
} from '../../../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch } from '../../../../redux/store';
import axiosInstance from '../../../../utils/axiosInstance';
import { messageInit } from '../../../../utils/common-constants';
import { APPLE_ENROLLMENT_DOWNLOAD_PLIST_ROUTE } from '../../../../utils/routes-defs';

interface IProps {
  open: boolean;
  handleDialog: () => void;
}

function AppleEnrollmentDialog(props: IProps) {
  const { open, handleDialog } = props;
  const [enrollment, setEnrollment] = useState<IAppleEnrollment>(initAppleEnrollment);

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setEnrollment((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));
  };

  const dispatch = useDispatch<AppDispatch>();

  const [apnsCertFile, setApnsCertFile] = useState<File | null>(null);
  const handleEnrollment = async () => {
    if (
      enrollment.appleId === EMPTY_STRING ||
      !enrollment.appleId.includes('@') ||
      !enrollment.appleId.includes('.')
    ) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Please enter a valid Apple ID',
        }),
      );
      return;
    }
    if (!apnsCertFile) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Please upload an APNS certificate',
        }),
      );
      return;
    }

    // Create a FormData object to send the file
    const formData = new FormData();
    formData.append('appleId', enrollment.appleId);
    formData.append('file', apnsCertFile as File);

    dispatch(createAppleEnrollment(formData));
    setEnrollment(initAppleEnrollment);
    setApnsCertFile(null);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = ['pem', 'cer', 'crt'];
    if (e.target.files && e.target.files.length > 0) {
      const extension = getExtension(e.target.files[0].name);
      if (allowedExtensions.includes(extension)) {
        setApnsCertFile(e.target.files[0]);
      } else {
        dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: APPLE_ENROLLMENT_MSG.FILE_TYPE_INVALID,
          }),
        );
      }
    }
  };

  const handleDownloadPlist = async () => {
    axiosInstance()
      .get(APPLE_ENROLLMENT_DOWNLOAD_PLIST_ROUTE)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        const filenameMatch = contentDisposition && contentDisposition.match(/filename=(.+)/);
        const filename = filenameMatch ? filenameMatch[1] : 'miniorange-VendorSignedCSR.plist';
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element to download tthe file
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: err.response.data.errors[0].message,
          }),
        );
      });
  };

  return (
    <Dialog fullWidth maxWidth='md' onClose={handleDialog} open={open}>
      <DialogTitle>Apple Enrollment and APN Certificate</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleDialog}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding={1}
          spacing={1}
        >
          <Grid item sm={8}>
            1. Download the Vendor CSR (.plist file).
          </Grid>
          <Grid item sm={4}>
            <FileDownloadButton
              text='Download'
              handleFileDownload={handleDownloadPlist}
              size='small'
              variant='contained'
            />
          </Grid>

          <Grid item sm={8}>
            2.
            <Link
              href={APPLE_ENROLLMENT_CONST.APPLE_SIGNUP_URL}
              underline='none'
              target='_blank'
              rel='noreferrer'
            >
              {' '}
              Create a new Apple ID{' '}
            </Link>
            preferably using a common corporate ID (e.g admin@companyname.com) for your organization
            as the same Apple ID is required during renewal of the APNS certificate.
          </Grid>

          <Grid item sm={8}>
            3. Sign In to
            <Link
              href={APPLE_ENROLLMENT_CONST.APPLE_APN_PORTAL_URL}
              target='_blank'
              rel='noreferrer'
              underline='none'
            >
              {' '}
              APN Portal{' '}
            </Link>
            and upload the CSR downloded in [1]. Download the APNS certificate generated.
          </Grid>

          <Grid item sm={8}>
            4. Save your Apple ID created in [2]
          </Grid>
          <Grid item sm={4}>
            <TextField
              required
              fullWidth
              size='small'
              id='appleId'
              name='appleId'
              placeholder='Corporate Apple ID'
              value={enrollment.appleId}
              onChange={onChange}
              variant='outlined'
              inputProps={{
                style: {
                  padding: '4px',
                },
              }}
            />
          </Grid>

          <Grid item sm={8}>
            5. Upload the APNS certificate (.pem/.cer/.crt file) downloaded in [3].
          </Grid>
          <Grid item sm={4}>
            <FileUploadButton
              text='Upload'
              handleFileUpload={handleFileUpload}
              size='small'
              variant='contained'
            />
            <Typography variant='subtitle2'>{apnsCertFile ? apnsCertFile.name : null}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant='contained' onClick={handleEnrollment}>
          Enroll
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(AppleEnrollmentDialog);
