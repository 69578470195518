import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileDownloadButton from '../../../../components/FileDownloadButton';
import { EMPTY_STRING } from '../../../../constants';
import {
  checkServiceDiscovery,
  updateMessage,
} from '../../../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import axiosInstance from '../../../../utils/axiosInstance';
import { messageInit } from '../../../../utils/common-constants';
import { APPLE_USER_ENROLLMENT_DOWNLOAD_SERVICE_DISCOVERY_FILE_ROUTE } from '../../../../utils/routes-defs';
import isValidDomain from '../../../../utils/validators';

interface IProps {
  open: boolean;
  handleDialog: () => void;
}
const htaccessContent = `
<Files "com.apple.remotemanagement">
Header set Content-type 'application/json'
</Files>`;
function AppleServiceDiscoveryDialog(props: IProps) {
  const { open, handleDialog } = props;
  const dispatch = useDispatch<AppDispatch>();
  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment.appleEnrollment);
  const [managedAppleIdDomain, setManagedAppleIdDomain] = useState<string>(EMPTY_STRING);

  useEffect(() => {
    setManagedAppleIdDomain(appleEnrollment.managedAppleIdDomain as string);
  }, [appleEnrollment.managedAppleIdDomain]);

  const handleDownloadServiceDiscoveryFile = async () => {
    if (!isValidDomain(managedAppleIdDomain)) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Invalid Managed Apple ID Domain',
        }),
      );
      return;
    }
    axiosInstance()
      .get(
        `${APPLE_USER_ENROLLMENT_DOWNLOAD_SERVICE_DISCOVERY_FILE_ROUTE}?managedAppleIdDomain=${managedAppleIdDomain}`,
        { responseType: 'blob' },
      )
      .then((response) => {
        dispatch(checkServiceDiscovery());
        const contentDisposition = response.headers['content-disposition'];
        const filenameMatch = contentDisposition && contentDisposition.match(/filename=(.+)/);
        const filename = filenameMatch ? filenameMatch[1] : 'miniorange-VendorSignedCSR.plist';
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element to download the file
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: 'Domain updated successfully',
          }),
        );
      })
      .catch(async (err) => {
        dispatch(
          updateMessage({
            ...messageInit,
            error: true,
            errorMessage: err.response.data.errors[0].message,
          }),
        );
      });
  };

  return (
    <Dialog fullWidth maxWidth='md' onClose={handleDialog} open={open}>
      <DialogTitle>Apple Enrollment and APN Certificate</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleDialog}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          padding={1}
          spacing={1}
        >
          <Grid item sm={8}>
            1. Save your Managed Apple ID doman (e.g. mycompany.com)
          </Grid>
          <Grid item sm={4}>
            <TextField
              required
              fullWidth
              error={!isValidDomain(managedAppleIdDomain)}
              size='small'
              id='managedAppleIdDomain'
              name='managedAppleIdDomain'
              placeholder='Managed Apple ID Domain'
              value={managedAppleIdDomain}
              onChange={(e) => setManagedAppleIdDomain(e.target.value)}
              variant='outlined'
              inputProps={{
                style: {
                  padding: '4px',
                },
              }}
            />
          </Grid>
          <Grid item sm={8}>
            2. Download the Service Discovery File (.json file).
          </Grid>
          <Grid item sm={4}>
            <FileDownloadButton
              text='Download'
              handleFileDownload={handleDownloadServiceDiscoveryFile}
              size='small'
              variant='contained'
            />
          </Grid>

          <Grid item sm={12}>
            3. Upload the file downloaded in [2] at:
            <p>{`https://${managedAppleIdDomain}/.well-known/com.apple.remotemanagement`}</p>
          </Grid>
          <Grid item sm={12}>
            <Alert severity='info'>
              <AlertTitle>Note</AlertTitle>
              <p>
                Please ensure the above URL is SSL secured and the content type is set to
                application/json.
              </p>
              <p>
                (Optional) Create a file with the name <strong>.htaccess</strong> at the root
                directory of your web server. Add the following content to it.
                <p>{htaccessContent}</p>
              </p>
            </Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant='contained' onClick={handleDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(AppleServiceDiscoveryDialog);
