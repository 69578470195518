import CachedIcon from '@mui/icons-material/Cached';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appleLogo from '../../assets/images/apple-logo.png';
import CustomHeader from '../../components/CustomHeader';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import { APPLE_ENROLLMENT_CONST, EMPTY_STRING, NULL } from '../../constants';
import MainLayout from '../../layouts/MainLayout';
import {
  checkServiceDiscovery,
  getAppleEnrollmentDetails,
  updateMessage,
} from '../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { COLOR_BLACK } from '../../theme';
import { messageInit } from '../../utils/common-constants';
import AppleEnrollmentDialog from './components/AppleEnrollmentDialog';
import AppleServiceDiscoveryDialog from './components/AppleServiceDiscoveryDialog';

export interface IAppleEnrollment {
  appleId: string;
  apnsTopic: string;
}

export const initAppleEnrollment: IAppleEnrollment = {
  appleId: EMPTY_STRING,
  apnsTopic: EMPTY_STRING,
};

function AppleEnrollment() {
  const dispatch = useDispatch<AppDispatch>();
  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment);
  const [isOpenEnrollmentDialog, setIsOpenEnrollmentDialog] = useState<boolean>(false);

  const [isOpenServiceDiscoveryDialog, setIsOpenServiceDiscoveryDialog] = useState<boolean>(false);
  const handleAppleEnrollmentDialog = () => {
    setIsOpenEnrollmentDialog((prev) => !prev);
  };
  const handleAppleServiceDiscoveryDialog = () => {
    setIsOpenServiceDiscoveryDialog((prev) => !prev);
  };

  useEffect(() => {
    if (
      appleEnrollment.appleEnrollment.appleId === EMPTY_STRING ||
      appleEnrollment.appleEnrollment.apnsTopic === EMPTY_STRING
    ) {
      dispatch(getAppleEnrollmentDetails());
    }
  }, []);

  useEffect(() => {
    dispatch(checkServiceDiscovery());
  }, []);

  return (
    <MainLayout>
      <CustomHeader logo={appleLogo} title={APPLE_ENROLLMENT_CONST.PAGE_HEADER} />
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' padding={2}>
        <Button
          variant='contained'
          onClick={handleAppleEnrollmentDialog}
          disabled={!!appleEnrollment.appleEnrollment.appleId}
        >
          Enroll
        </Button>
        <AppleEnrollmentDialog
          open={isOpenEnrollmentDialog}
          handleDialog={handleAppleEnrollmentDialog}
        />
        <AppleServiceDiscoveryDialog
          open={isOpenServiceDiscoveryDialog}
          handleDialog={handleAppleServiceDiscoveryDialog}
        />
      </Grid>
      <Grid container direction='row' justifyContent='flex-start' padding={2}>
        <Grid item sm={4}>
          <Card elevation={1} sx={{ height: 260 }}>
            <CardContent>
              <Typography variant='h6' color='text.primary' gutterBottom>
                APNS Certificate Details
              </Typography>
              <Typography color='text.secondary' fontSize={16}>
                <span style={{ color: COLOR_BLACK }}>Apple ID</span> :{' '}
                {appleEnrollment.appleEnrollment.appleId}
              </Typography>
              <Typography color='text.secondary' fontSize={16}>
                <span style={{ color: COLOR_BLACK }}>APNS Topic</span> :{' '}
                {appleEnrollment.appleEnrollment.apnsTopic}
              </Typography>
              <Typography color='text.secondary' fontSize={16}>
                <span style={{ color: COLOR_BLACK }}>Valid till</span> :{' '}
                {appleEnrollment.appleEnrollment.apnsValidity !== NULL &&
                  new Date(appleEnrollment.appleEnrollment.apnsValidity)?.toUTCString()}
              </Typography>
              <Typography color='text.secondary' fontSize={16}>
                <span style={{ color: COLOR_BLACK }}>Days Remaining</span> :{' '}
                {appleEnrollment.appleEnrollment.apnsRenewalDaysRemaining ===
                APPLE_ENROLLMENT_CONST.APNS_DEFAULT_REMAINING_DAYS
                  ? EMPTY_STRING
                  : appleEnrollment.appleEnrollment.apnsRenewalDaysRemaining}
              </Typography>
            </CardContent>
            <CardActions sx={{ margin: 1 }}>
              <Button
                variant='contained'
                size='small'
                onClick={handleAppleEnrollmentDialog}
                disabled={
                  appleEnrollment.appleEnrollment.apnsRenewalDaysRemaining >
                  APPLE_ENROLLMENT_CONST.APNS_REMAINING_DAYS_THREE_MONTHS
                }
              >
                Renew APNS Cert
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item sm={0.25} />
        <Grid item sm={4}>
          <Card elevation={1} sx={{ height: 260 }}>
            <CardContent>
              <Typography variant='h6' color='text.primary' gutterBottom>
                {/* Apple Sign in with Work/School */}
                Self Enrollment Configuration
              </Typography>
              <Typography color='text.secondary' fontSize={16}>
                <span style={{ color: COLOR_BLACK }}>Domain</span> :{' '}
                {appleEnrollment.appleEnrollment.managedAppleIdDomain}
              </Typography>
              <Typography color='text.secondary' fontSize={16}>
                <span style={{ color: COLOR_BLACK }}>Service Discovery Token</span> :{' '}
                {appleEnrollment.appleEnrollment.serviceDiscoveryToken}
              </Typography>
              <Typography color='text.secondary' fontSize={16}>
                <span style={{ color: COLOR_BLACK }}>Server</span> : https://
                {appleEnrollment.appleEnrollment.managedAppleIdDomain !== EMPTY_STRING
                  ? appleEnrollment.appleEnrollment.managedAppleIdDomain
                  : 'your_domain'}
                /.well-known/com.apple.remotemanagement
                <span style={{ marginLeft: 8 }}>
                  {appleEnrollment.appleEnrollment.serviceDiscoveryStatus ? (
                    <CheckCircleIcon color='success' fontSize='small' />
                  ) : (
                    <CancelIcon color='error' fontSize='small' />
                  )}
                </span>
                <span>
                  {appleEnrollment.appleEnrollment.serviceDiscoveryStatus ||
                  appleEnrollment.appleEnrollment.serviceDiscoveryToken ? (
                    <CachedIcon
                      onClick={() => dispatch(checkServiceDiscovery())}
                      fontSize='small'
                    />
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </span>
              </Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', margin: 1 }}>
              <Button
                variant='contained'
                size='small'
                sx={{ marginRight: 2 }}
                onClick={() => setIsOpenServiceDiscoveryDialog((prev) => !prev)}
              >
                Configure Service Discovery
              </Button>
              <Link to='/manage/oauthconfig'>
                <Button
                  variant='contained'
                  size='small'
                  onClick={() => setIsOpenServiceDiscoveryDialog((prev) => !prev)}
                >
                  Configure OAuth 2.0 Server
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      {(appleEnrollment.message.error || appleEnrollment.message.success) && (
        <ErrorSuccessSnackbar
          opened={appleEnrollment.message.error || appleEnrollment.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={appleEnrollment.message.errorMessage}
          successMessage={appleEnrollment.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AppleEnrollment;
