/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Autocomplete,
  TextField,
  MenuItem,
  Typography,
  debounce,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Edit as EditIcon } from '@mui/icons-material';
import { AppDispatch } from '../../redux/store';
import { ISelectItem } from './definitions';
import { setSearchkey } from '../../redux/slices/users/usersSlice';

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface IProps {
  fieldId: string;
  entries: ISelectItem[];
  loading: boolean;
  selectedEntry: { _id?: string; value1: string; value2: string };
  handler: any;
  handeleReset: () => void;
}

function EnrollmentAutoComplete(props: IProps) {
  const { fieldId, entries, handeleReset, loading, selectedEntry, handler } = props;
  const dispatch = useDispatch<AppDispatch>();

  const handleSearch = debounce((value: string) => {
    if (fieldId !== 'user-select') return;
    dispatch(setSearchkey(value));
  }, 500);

  return (
    <FormControl fullWidth>
      {selectedEntry?.value1 !== '' ? (
        <Button
          sx={{
            height: 'fit-content',
            padding: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          variant='outlined'
          color='inherit'
          onClick={handeleReset}
        >
          <Box display='flex' flexDirection='column' textAlign='start'>
            <Typography variant='body1'>{selectedEntry.value1}</Typography>
            <Typography variant='body2'>{selectedEntry.value2}</Typography>
          </Box>
          <EditIcon />
        </Button>
      ) : (
        <Autocomplete
          id={fieldId}
          options={entries}
          loading={loading}
          onChange={(_, value: ISelectItem | null) => handler(value)}
          onInputChange={(_, value: string) => {
            handleSearch(value);
          }}
          getOptionLabel={(option) => option.value1}
          renderInput={(props: AutocompleteRenderInputParams) => (
            <TextField {...props} label='Search' />
          )}
          renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option) => (
            <MenuItem key={option._id} sx={{ display: 'flex', flexDirection: 'column' }} {...props}>
              <Typography variant='body1'>{option.value1}</Typography>
              <Typography variant='body2'>{option.value2}</Typography>
            </MenuItem>
          )}
        />
      )}
    </FormControl>
  );
}

export default EnrollmentAutoComplete;
