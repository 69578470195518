import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, IUser, messageInit } from '../../../utils/common-constants';
import {
  USERS_GET_ROUTE,
  USER_CREATE_ROUTE,
  USER_DELETE_ROUTE,
  USER_SYNC_ROUTE,
} from '../../../utils/routes-defs';
import { IUserCreatePayload } from '../../../pages/Users';
import { RootState } from '../../store';

interface IUsers {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  users: IUser[];
  loading: boolean;
  message: IMessage;
  page: number;
  limit: number;
  total: number;
  isAll: boolean;
  searchKey: string;
}

const initialState: IUsers = {
  users: [],
  loading: false,
  message: messageInit,
  page: 1,
  limit: 25,
  total: 0,
  isAll: false,
  searchKey: '',
};

export const getUsers = createAsyncThunk('users/getUsers', async (_, thunkAPI) => {
  try {
    const { getState } = thunkAPI;
    const { page, limit, isAll, searchKey } = (getState() as RootState).user;

    const response = await axiosInstance().get(
      `${USERS_GET_ROUTE}?page=${page}&limit=${limit}&all=${isAll}&search=${searchKey}`,
    );

    return response.data;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.errors) {
      return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
    }
    throw err;
  }
});

export const syncUser = createAsyncThunk('users/syncUser', async (_, thunkAPI) => {
  try {
    const response = await axiosInstance().post(USER_SYNC_ROUTE);
    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.errors) {
      return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
    }
    throw err;
  }
});

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload: IUserCreatePayload, thunkAPI) => {
    try {
      const response = await axiosInstance().post(USER_CREATE_ROUTE, payload);
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (payload: { userId: string }, thunkAPI) => {
    try {
      const { userId } = payload;
      const response = await axiosInstance().delete(`${USER_DELETE_ROUTE}?userId=${userId}`);
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);
const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setAll: (state, action) => {
      state.isAll = action.payload;
    },
    setSearchkey: (state, action) => {
      state.searchKey = action.payload;
    },
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
    updateTotalUsersCount: (state, action) => {
      state.total = action.payload;
    },
    updateUsersArray: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get users
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const { users, message, total } = action.payload;
      const mappedUsers = users.map((userItem: IUser, idx: number) => ({
        ...userItem,
        id: (state.page - 1) * state.limit + idx + 1,
      }));

      state.loading = false;
      state.total = total;
      state.users = mappedUsers;
      state.isAll = false;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isAll = false;
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // create user
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      const { users, message } = action.payload;
      const newUsers: IUser[] = [...users, ...state.users];

      const mappedUsers = newUsers.map((user: IUser, idx: number) => ({
        ...user,
        id: (state.page - 1) * state.limit + idx + 1,
      }));

      state.users = mappedUsers;
      state.loading = false;
      state.total += users.length;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // sync user
    builder.addCase(syncUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(syncUser.fulfilled, (state, action) => {
      const { message, users } = action.payload;
      users.forEach((user: IUser) => {
        state.users.unshift(user);
      });
      state.loading = false;
      state.users = state.users.map((user: IUser, idx: number) => ({
        ...user,
        id: (state.page - 1) * state.limit + idx + 1,
      }));
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(syncUser.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // delete user
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const { message } = action.payload;
      const { userId } = action.meta.arg;
      const updatedTableData = state.users.filter((item: IUser) => item._id !== userId);
      const updatedTableDataWithIds = updatedTableData.map((user: IUser, idx: number) => ({
        ...user,
        id: (state.page - 1) * state.limit + idx + 1,
      }));

      state.users = updatedTableDataWithIds;
      state.total -= 1;
      state.loading = false;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const {
  updateMessage,
  setPage,
  setLimit,
  setAll,
  updateTotalUsersCount,
  updateUsersArray,
  setSearchkey,
} = usersSlice.actions;
export default usersSlice;
