import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Grid, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomHeader from '../../../components/CustomHeader';
import ErrorSuccessSnackbar from '../../../components/ErrorSuccessSnackbar';
import { EMPTY_STRING, OAUTH2_SERVER_NAME } from '../../../constants';
import MainLayout from '../../../layouts/MainLayout';
import {
  getOAuthConfig,
  IOAuthConfig,
  testOAuthConfig,
  updateMessage,
  updateOAuthConfig,
} from '../../../redux/slices/manage/oauthSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { messageInit } from '../../../utils/common-constants';
import PropertyAndInput from '../../AndroidPolicies/components/PropertyAndInput';
import PropertyAndSelectInput from '../../AndroidPolicies/components/PropertyAndSelectInput';

function OAuth() {
  const dispatch = useDispatch<AppDispatch>();
  const oAuthConfigRedux = useSelector((state: RootState) => state.oAuthConfig);
  const [oAuthConfig, setOAuthConfig] = useState(oAuthConfigRedux.oAuthConfig);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const field = e.target.name as keyof IOAuthConfig;
    const value = e.target.value as string;
    setOAuthConfig((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveOAuthConfig = () => {
    dispatch(updateOAuthConfig(oAuthConfig));
  };

  useEffect(() => {
    setOAuthConfig(oAuthConfigRedux.oAuthConfig);
  }, [JSON.stringify(oAuthConfigRedux.oAuthConfig)]);

  useEffect(() => {
    dispatch(getOAuthConfig());
  }, []);

  const isRequiredField = (): boolean =>
    !!(
      oAuthConfig.name !== EMPTY_STRING &&
      oAuthConfig.name !== OAUTH2_SERVER_NAME.GOOGLE &&
      oAuthConfig.name !== OAUTH2_SERVER_NAME.MICROSOFT_ENTRA_ID
    );

  const handleCopyRedirectURI = () => {
    navigator.clipboard.writeText(oAuthConfigRedux.redirectUri);
    dispatch(
      updateMessage({ ...messageInit, success: true, successMessage: 'Redirect URI copied' }),
    );
  };

  return (
    <MainLayout>
      <CustomHeader title='Configure OAuth2.0 Server' />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={3}
        width='50%'
        // spacing={2}
      >
        <PropertyAndSelectInput
          data={{
            property: 'OAuth Provider',
            name: 'name',
            value: oAuthConfig.name,
            menuItems: [
              {
                view: 'Google',
                value: OAUTH2_SERVER_NAME.GOOGLE,
              },
              {
                view: 'Microsoft Entra ID',
                value: OAUTH2_SERVER_NAME.MICROSOFT_ENTRA_ID,
              },
              {
                view: 'Custom',
                value: 'custom',
              },
            ],
            selectFieldLabel: 'Select',
            direction: 'row',
            width: '60%',
            isRequired: true,
            handleChange,
            propertyWidth: 4,
            inputWidth: 8,
          }}
        />

        <Grid item sm={12} marginBottom={2} display={isRequiredField() ? 'block' : 'none'}>
          {/* Name */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='Name'
            name='name'
            value={oAuthConfig.name}
            placeholder='Enter IDP name'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
          />
          {/* Client ID */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='Client ID'
            name='clientId'
            value={oAuthConfig.clientId}
            placeholder='Enter Client ID'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
          />

          {/* Client Secret */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='Client Secret'
            name='clientSecret'
            value={oAuthConfig.clientSecret}
            placeholder='Enter Client Secret'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
          />

          {/* Redirect URI */}
          <PropertyAndInput
            property='Redirect URL'
            name='redirectURI'
            value={oAuthConfigRedux.redirectUri}
            placeholder='Copy the redirect URL'
            onChange={() => {}}
            disabled={isRequiredField()}
            sx={{}}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ cursor: 'pointer', color: 'black' }}>
                  <ContentCopyIcon fontSize='small' onClick={handleCopyRedirectURI} />
                </InputAdornment>
              ),
            }}
            propertyWidth={4}
            inputWidth={8}
          />

          {/* Scope */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='Scope'
            name='scope'
            value={oAuthConfig.scope}
            placeholder='Enter Scope'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
          />

          {/* Authorization URL */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='Authorization URL'
            name='authorizationUrl'
            value={oAuthConfig.authorizationUrl}
            placeholder='Enter Authorization URL'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
          />

          {/* Token URL */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='Token URL'
            name='tokenUrl'
            value={oAuthConfig.tokenUrl}
            placeholder='Enter Token URL'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
          />

          {/* User Info URL */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='User Info URL'
            name='userInfoUrl'
            value={oAuthConfig.userInfoUrl}
            placeholder='Enter User Info URL'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
          />

          {/* User Claim Mapping */}
          <PropertyAndInput
            isRequired={isRequiredField()}
            property='Attributes Mapping'
            name='userClaimMapping'
            value={oAuthConfig.userClaimMapping}
            placeholder='Enter Attributes Mapping'
            onChange={handleChange}
            propertyWidth={4}
            inputWidth={8}
            multiline
            rows={4}
          />
        </Grid>
        <Button
          size='medium'
          variant='contained'
          sx={{ marginRight: 2 }}
          onClick={handleSaveOAuthConfig}
        >
          Save Configuration
        </Button>
        <Button
          size='medium'
          variant='contained'
          disabled={!isRequiredField()}
          onClick={() => dispatch(testOAuthConfig())}
        >
          Test Configuration
        </Button>
      </Grid>

      {(oAuthConfigRedux.message.error || oAuthConfigRedux.message.success) && (
        <ErrorSuccessSnackbar
          opened={oAuthConfigRedux.message.error || oAuthConfigRedux.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={oAuthConfigRedux.message.errorMessage}
          successMessage={oAuthConfigRedux.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default OAuth;
